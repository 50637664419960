.form-row {
  margin-bottom: 20px;
  .mat-mdc-form-field {
    width: 100%;
  }
}

.form-row-dbl {
  display: flex;
  .form-row {
    width: 250px;
    margin-right: 85px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.row-addition {
  @include flex(row, space-between, center);
}

.form-sect {
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
}

.chk-box {
  font-size: 14px;
  .mat-checkbox-label {
    line-height: 20px;
  }
  .mat-checkbox-layout {
    white-space: normal;
  }

  &.grey {
    color: $clr-darkgrey;
  }

  &.chk-top {
    .mat-checkbox-inner-container {
      margin-top: 2px;
    }
  }
}

.form-btns {
  @include flex(row, flex-end, center);
  margin-top: 30px;
  .btn {
    margin-left: 25px;
  }
}

.select-outline {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline,
  .mat-form-field-label,
  .mat-select-arrow,
  .mat-select-value-text {
    color: $clr-darkgrey;
  }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: none;
  }
  .mat-form-field-flex {
    margin-top: 0;
    height: 44px;
    align-items: normal;
  }
  .mat-form-field-infix {
    border-top: 0;
    padding: 16px 0;
  }

  .mat-form-field-disabled {
    &.mat-form-field-appearance-outline .mat-form-field-outline,
    .mat-form-field-label,
    .mat-select-arrow,
    .mat-select-value-text {
      color: $clr-scroll;
    }
  }

  .mat-mdc-form-field {
    width: 210px;
  }

  .mat-mdc-icon-button {
    top: 2px;
    margin-right: -5px;
  }
}
