// fonts
$font-main: Roboto, 'Helvetica Neue', sans-serif;

// colors
$clr-main: rgba(0, 0, 0, 0.87);
$clr-white: #ffffff;
$clr-light-red: #ff6565;
$clr-blue: #3b86ff;
$clr-purple: #a3a0fb;
$clr-dark-blue: #43425d;
$clr-text-dark-grey: #4d4f5c;
$clr-icon-grey: #a5a4bf;
$clr-orange: #ffca83;
$clr-green: #4ad991;
$clr-light-grey: #f0f0f7;
$clr-highlighted-row: #d6d7db;
$clr-pink: #f79393;
$clr-primary: #54527b;
$clr-accent: #ff4081;
$clr-warn: #f44336;
$clr-maintenance: #1b83dd;
$clr-grey: #9fa3a7;
$clr-text-grey: #54527b;
$clr-sidemenu-background: #56556d;
$clr-darkgrey: #4e505d;
$clr-lightgrey: $clr-light-grey;
$clr-side: $clr-dark-blue;
$clr-dimming-settings-block-background: #75d2fb;
$clr-dimming-settings-block-border: #0eb5ff;
$clr-dimming-settings-percent-line: #707070;
$clr-dimming-settings-title-background: rgba(165, 164, 191, 0.15);
$clr-group-removed: #f29a9a;
$clr-group-new: #b9eeb4;
$clr-warning-yellow: #ffda22;
$clr-warning-orange: #ed6d03;
$clr-warning-red: #d3302f;
$clr-select-item-background: #66657c;
$clr-toggle-track: #7986cb;
$clr-toggle-handle: #3949ab;
$clr-table-line-gray: inherit; // #fafafa;
$clr-small-title-text: #a5a5a5;

$clr-scroll: #bbbbbb;

$clr-schedule-close-icon: #d58383;
// breakpoints
$breakpoint-tablet: 721px;
$breakpoint-desktop: 1025px;
$breakpoint-desktop-wide: 1280px;

// animations
$anim: cubic-bezier(0.25, 0.8, 0.25, 1);

$default-shadow:
  0 3px 3px -2px rgba(0, 0, 0, 0.2),
  0 3px 4px 0 rgba(0, 0, 0, 0.14),
  0 1px 8px 0 rgba(0, 0, 0, 0.12);
$small-shadow: 0px 3px 6px #00000029;
