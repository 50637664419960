.unTab {
  &__wrapper {
    padding: 0 6px;
  }

  &__table {
    width: 100%;
    margin-top: 8px;
    box-shadow: $small-shadow;
    table-layout: fixed;

    td.mat-mdc-cell:first-of-type,
    td.mat-mdc-footer-cell:first-of-type,
    th.mat-mdc-header-cell:first-of-type {
      padding-left: 10px;
    }

    .mat-mdc-header-row,
    .mat-mdc-row {
      :last-of-type {
        padding-right: 0;
      }

      .mat-mdc-cell,
      .mat-mdc-header-cell {
        padding: 5px 7px;
        word-wrap: break-word;

        &.cell-cbx {
          width: 42px;
          padding-left: 15px;
        }
      }

      .mat-mdc-header-cell {
        background-color: $clr-white;
      }
    }

    .mat-mdc-header-row {
      height: 46px;
    }

    .mat-mdc-row {
      height: 32px;
      @include user-select(none);

      td {
        color: $clr-text-dark-grey;
      }

      &.--alarm td {
        color: $clr-light-red;
      }

      &.--acknowledged td {
        color: purple;
      }

      &.--maintenance td {
        color: $clr-blue;
      }

      &.highlighted {
        .--selected-row {
          background-color: $clr-pink;
        }
        background-color: $clr-highlighted-row;
      }

      .settings-icon {
        @include material-icon-size(16px);
      }
    }
  }

  &__no-results-message {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    color: $clr-grey;
    padding: 20px 0;
  }

  &__box {
    margin: 8px 6px;
    box-shadow: $small-shadow;
    background-color: $clr-white;

    .unTab__table {
      margin: 0;
      box-shadow: none;
    }

    .tbl-panel {
      padding: 10px;
    }
  }
}
