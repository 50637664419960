.tbl {
  width: 100%;
  table-layout: fixed;

  &.tbl-flexible {
    table-layout: auto;
  }

  .mat-mdc-header-cell,
  .mat-mdc-cell {
    padding: 5px 10px;
    word-wrap: break-word;
  }

  .mat-mdc-row {
    &:nth-child(odd) {
      background-color: #fafafa;
    }

    &.row-locked {
      td:not(.cell-actions) {
        opacity: 0.5;
      }
    }

    &.row-new td {
      background-color: $clr-light-grey;
      color: $clr-warn;
    }
  }

  .cell-centered {
    text-align: center;
  }

  .cell-right {
    text-align: right;
  }

  .cell-chbox {
    width: 90px;
  }

  .cell-descr {
    width: 40%;
  }

  .cell-delete {
    width: 120px;
  }
}

.cell-cbx {
  width: 55px;
}

.cell-empty {
  width: 1px;
}

.cell-id {
  width: 55px;
}

.tbl-panel {
  padding: 10px 0 20px;
  border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
  @include flex();

  .actions-box {
    @include flex();
    .btn {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    .btn-refresh {
      color: $clr-grey;
    }

    &.right {
      margin-left: auto;
    }
  }
}
