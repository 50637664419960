@use '@angular/material' as mat;

$c2-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $headline-1:
    mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: normal),
  $headline-2:
    mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: normal),
  $headline-3:
    mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: normal),
  $headline-4:
    mat.m2-define-typography-level(34px, 40px, 400, $letter-spacing: normal),
  $headline-5:
    mat.m2-define-typography-level(24px, 32px, 400, $letter-spacing: normal),
  $headline-6:
    mat.m2-define-typography-level(20px, 32px, 500, $letter-spacing: normal),
  $subtitle-1:
    mat.m2-define-typography-level(16px, 28px, 400, $letter-spacing: normal),
  $body-1:
    mat.m2-define-typography-level(15px, 24px, 400, $letter-spacing: normal),
  $subtitle-2:
    mat.m2-define-typography-level(14px, 24px, 500, $letter-spacing: normal),
  $body-2:
    mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: normal),
  $caption:
    mat.m2-define-typography-level(12px, 20px, 400, $letter-spacing: normal),
  $button:
    mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: normal),
);

@include mat.all-component-typographies($c2-typography);

@include mat.core();

@import '@angular/material/prebuilt-themes/indigo-pink.css';

$primary-palette: mat.m2-define-palette(mat.$m2-indigo-palette);
$accent-palette: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
    ),
  )
);

// The following mixins include base theme styles that are only needed once per application. These
// theme styles do not depend on the color, typography, or density settings in your theme. However,
// these styles may differ depending on the theme's design system. Currently all themes use the
// Material 2 design system, but in the future it may be possible to create theme based on other
// design systems, such as Material 3.
//
// Please note: you do not need to include the 'base' mixins, if you include the corresponding
// 'theme' mixin elsewhere in your Sass. The full 'theme' mixins already include the base styles.
//
// To learn more about "base" theme styles visit our theming guide:
// https://material.angular.io/guide/theming#theming-dimensions
//
// TODO(v17): Please move these @include statements to the preferred place in your Sass, and pass
// your theme to them. This will ensure the correct values for your app are included.
@include mat.all-component-bases(
  $light-theme
); /* TODO(v17): pass $your-theme here */

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component that you are using.
body.light,
body .light {
  // Adds density level 0 styles
  @include mat.all-component-colors($light-theme);
}
body.dark,
body .dark {
  // Adds density level 0 styles
  @include mat.all-component-colors($dark-theme);
}
