.view-wrapper {
  padding: 0 50px 30px;
  &-centered {
    max-width: 1420px;
    margin: 0 auto;
  }
}

.view-topbar {
  @include flex(row, space-between, center);
  padding: 25px 0;

  .form-btns {
    margin-top: 0;
  }
}

.view-box {
  padding: 30px 50px;
  background-color: $clr-white;
  box-shadow: $small-shadow;
  position: relative;
  &-wide {
    padding: 10px 30px 30px;
  }

  .unTab__no-results-message {
    padding-top: 50px;
  }
}

.tabs-content {
  .mat-tab-label {
    background-color: $clr-white;
    &.mat-tab-label-active {
      opacity: 1;
    }
  }
  .mat-tab-body-wrapper {
    box-shadow: 0 3px 6px #00000029;
  }
}

.widget-loader {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  @include flex(row, center, center);
}
