@import 'variables';

@mixin flex($direction: row, $spacing: space-between, $aligning: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $spacing;
  align-items: $aligning;
}

@mixin material-icon-size($size: 24px) {
  font-size: $size;
  width: $size;
  height: $size;
  line-height: $size;
}

@mixin for-phone-only {
  @media (max-width: $breakpoint-tablet - 1) {
    @content;
  }
}

@mixin for-tablet-up {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

@mixin for-tablet-only {
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin for-phone-tablet-only {
  @media (max-width: $breakpoint-desktop - 1) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

@mixin for-desktop-wide-up {
  @media (min-width: $breakpoint-desktop-wide) {
    @content;
  }
}

// Placeholder text still needs vendor-specific prefixes: https://css-tricks.com/almanac/selectors/p/placeholder/
@mixin placeholder($color, $opacity: 1) {
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color !important;
    opacity: $opacity !important;
  }
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin word-wrap($hyphen: false) {
  // These are technically the same, but we use both as IE11 has only partial support for overflow-wrap
  overflow-wrap: break-word;
  word-wrap: break-word;

  // The following won't work in IE11
  word-break: break-word;

  @if $hyphen == true {
    // Adds a hyphen where the word breaks, if supported by the browser
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  }
}

@mixin custom-scroll() {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $clr-scroll;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $clr-scroll;
    border-radius: 9px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: darken($clr-scroll, 30%);
  }
}
