@import '../variables';

@mixin mat-select($width) {
  width: $width;
  ::ng-deep {
    .mat-form-field-wrapper {
      .mat-form-field-flex {
        .mat-form-field-infix {
          .mat-select-value-text {
            font: Normal 15px/15px $font-main;
            letter-spacing: 0;
            color: $clr-text-grey;
          }
        }
      }
    }
  }
}

.cdk-overlay-pane:has(> .langSelect) {
  width: auto !important;
  max-width: 300px;
  left: 305px !important;

  .mat-pseudo-checkbox-checked::after {
    color: $clr-primary;
  }
}
