/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/reset';
@import 'styles/base';
@import 'styles/layout';

@import 'styles/elements/typography';
@import 'styles/elements/buttons';
@import 'styles/elements/forms';
@import 'styles/elements/modals';
@import 'styles/elements/modal-reports';
@import 'styles/elements/tables';
@import 'styles/elements/tables-units';
@import 'styles/elements/toogle';
@import 'styles/elements/select';
