.reports-options {
  padding-top: 20px;
  .reports-row {
    @include flex();
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    .mat-mdc-form-field {
      width: 250px;
    }
  }

  .btns-row {
    justify-content: flex-end;
    padding-top: 15px;
  }

  .btns-box {
    @include flex();
    width: 250px;

    .btn {
      flex: 1;
      &:last-child {
        margin-left: 15px;
      }
      &:only-child {
        margin-left: 0;
      }
    }
  }
}
