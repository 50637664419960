@import 'mixins';
@import 'variables';

html {
  height: 100%;
}

body {
  font-family: $font-main;
  font-size: 15px;
  color: $clr-main;
  height: 100%;
  @include custom-scroll();
}

a {
  color: $clr-primary;
  text-decoration: underline;
}

.noUppercase {
  text-transform: lowercase;
}

.graph-tooltip {
  font-size: 14px;
  padding: 10px;

  &__color-icon {
    display: inline-block;
    height: 12px;
    width: 12px;
    margin-right: 5px;
    color: #5b646b;
    border-radius: 3px;
  }
}

.logout-lnk {
  color: $clr-warn;
  text-decoration: underline;
}

.icon-button {
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
  display: flex;

  &:focus {
    outline: none;
  }
}

.mat-mdc-select-panel {
  &:focus {
    outline: none;
  }
}

.snackbar-white {
  background-color: $clr-white;
  color: $clr-main;
}

.placeholder-empty {
  padding: 124px 0 100px;
  font-size: 18px;
  text-align: center;
  color: $clr-grey;
}

.leaflet-top {
  z-index: 998;
}

.leaflet-bottom {
  z-index: 997;
}

.leaflet-bottom.leaflet-right {
  padding: 4px;
  background-color: rgb(255 255 255 / 60%);
  width: 124px;
  &::after {
    content: '';
    display: block;
    background-image: url('../assets/images/map-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    height: 28px;
  }
  .leaflet-control-attribution.leaflet-control {
    display: none;
  }
}

.mat-mdc-paginator {
  margin: -8px 0;
}

.logs-paginator {
  position: absolute;
  top: 17px;
  right: 30px;
  background-color: transparent;
}

.mat-mdc-table-sticky {
  top: -1px !important;
}

.mat-mdc-progress-spinner.mat-accent circle,
.mat-mdc-progress-spinner.mat-accent circle {
  stroke: $clr-white !important;
}

// fix dropdown for EDGE
@supports (-ms-ime-align: auto) {
  .mat-mdc-select-panel {
    min-width: calc(100% + 32px) !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.brightness .swatch,
#Fill-4-brightness {
  filter: brightness(0.9);
}

.swatch {
  border: 1px solid rgba(0, 0, 0, 0.15);
}
