h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
}

.view-heading {
  font: bold 28px/34px $font-main;
  color: $clr-text-grey;
  margin: 0;
}

.heading-bordered {
  font-size: 18px;
  color: $clr-darkgrey;
  border-bottom: 1px $clr-grey solid;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
