.btn {
  &.btn-wide {
    min-width: 180px;
  }

  &.btn-large {
    width: 100%;
    min-height: 50px;
    font-size: 20px;
  }

  &.btn-dark {
    background-color: $clr-side !important;
    color: $clr-white !important;
  }

  &.btn-white {
    background-color: $clr-white;
  }

  &.mat-mdc-outlined-button {
    border-color: currentColor;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.btn-download {
  font-size: 22px;
  color: $clr-darkgrey;
}

.btns-actions {
  display: inline-flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  .mat-mdc-button {
    .mat-icon {
      font-size: 16px;
    }
  }
}
