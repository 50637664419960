.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  --mat-sys-on-surface: initial;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-sys-background, transparent));
  color: var(--mat-app-text-color, var(--mat-sys-on-background, inherit));
}

html {
  --mat-app-background-color: var(--sys-background);
  --mat-app-text-color: var(--sys-on-background);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
html {
  --mat-ripple-color: color-mix(in srgb, var(--sys-on-surface) 10%, transparent);
}
html {
  --mat-option-selected-state-label-text-color: var(--sys-on-secondary-container);
  --mat-option-label-text-color: var(--sys-on-surface);
  --mat-option-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-option-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-option-selected-state-layer-color: var(--sys-secondary-container);
  --mat-option-label-text-font: var(--sys-label-large-font);
  --mat-option-label-text-line-height: var(--sys-label-large-line-height);
  --mat-option-label-text-size: var(--sys-body-large-size);
  --mat-option-label-text-tracking: var(--sys-label-large-tracking);
  --mat-option-label-text-weight: var(--sys-body-large-weight);
}
html {
  --mat-optgroup-label-text-color: var(--sys-on-surface-variant);
  --mat-optgroup-label-text-font: var(--sys-title-small-font);
  --mat-optgroup-label-text-line-height: var(--sys-title-small-line-height);
  --mat-optgroup-label-text-size: var(--sys-title-small-size);
  --mat-optgroup-label-text-tracking: var(--sys-title-small-tracking);
  --mat-optgroup-label-text-weight: var(--sys-title-small-weight);
}
html {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-primary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-primary);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
}
html {
  --mdc-elevated-card-container-color: var(--sys-surface-container-low);
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 12px;
}
html {
  --mdc-outlined-card-container-color: var(--sys-surface);
  --mdc-outlined-card-outline-color: var(--sys-outline-variant);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-shape: 12px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mat-card-subtitle-text-color: var(--sys-on-surface);
  --mat-card-title-text-font: var(--sys-title-large-font);
  --mat-card-title-text-line-height: var(--sys-title-large-line-height);
  --mat-card-title-text-size: var(--sys-title-large-size);
  --mat-card-title-text-tracking: var(--sys-title-large-tracking);
  --mat-card-title-text-weight: var(--sys-title-large-weight);
  --mat-card-subtitle-text-font: var(--sys-title-medium-font);
  --mat-card-subtitle-text-line-height: var(--sys-title-medium-line-height);
  --mat-card-subtitle-text-size: var(--sys-title-medium-size);
  --mat-card-subtitle-text-tracking: var(--sys-title-medium-tracking);
  --mat-card-subtitle-text-weight: var(--sys-title-medium-weight);
}
html {
  --mdc-linear-progress-active-indicator-color: var(--sys-primary);
  --mdc-linear-progress-track-color: var(--sys-surface-variant);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}
html {
  --mdc-plain-tooltip-container-color: var(--sys-inverse-surface);
  --mdc-plain-tooltip-supporting-text-color: var(--sys-inverse-on-surface);
  --mdc-plain-tooltip-supporting-text-line-height: var(--sys-body-small-line-height);
  --mdc-plain-tooltip-supporting-text-font: var(--sys-body-small-font);
  --mdc-plain-tooltip-supporting-text-size: var(--sys-body-small-size);
  --mdc-plain-tooltip-supporting-text-weight: var(--sys-body-small-weight);
  --mdc-plain-tooltip-supporting-text-tracking: var(--sys-body-small-tracking);
  --mdc-plain-tooltip-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: var(--sys-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-primary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-filled-text-field-container-color: var(--sys-surface-variant);
  --mdc-filled-text-field-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 4%, transparent);
  --mdc-filled-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-hover-label-text-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-color: var(--sys-on-surface);
  --mdc-filled-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-filled-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-filled-text-field-error-label-text-color: var(--sys-error);
  --mdc-filled-text-field-active-indicator-color: var(--sys-on-surface-variant);
  --mdc-filled-text-field-disabled-active-indicator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-text-field-hover-active-indicator-color: var(--sys-on-surface);
  --mdc-filled-text-field-error-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--sys-on-error-container);
  --mdc-filled-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-filled-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-filled-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-filled-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-caret-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-primary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-primary);
  --mdc-outlined-text-field-label-text-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-hover-label-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-color: var(--sys-on-surface);
  --mdc-outlined-text-field-disabled-input-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--sys-on-surface-variant);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-label-text-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-outline-color: var(--sys-outline);
  --mdc-outlined-text-field-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-text-field-hover-outline-color: var(--sys-on-surface);
  --mdc-outlined-text-field-error-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-error-hover-outline-color: var(--sys-on-error-container);
  --mdc-outlined-text-field-error-outline-color: var(--sys-error);
  --mdc-outlined-text-field-label-text-font: var(--sys-body-large-font);
  --mdc-outlined-text-field-label-text-size: var(--sys-body-large-size);
  --mdc-outlined-text-field-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-outlined-text-field-label-text-weight: var(--sys-body-large-weight);
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mat-form-field-focus-select-arrow-color: var(--sys-primary);
  --mat-form-field-disabled-input-text-placeholder-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-state-layer-color: var(--sys-on-surface);
  --mat-form-field-error-text-color: var(--sys-error);
  --mat-form-field-select-option-text-color: #1c1b1e;
  --mat-form-field-select-disabled-option-text-color: rgba(28, 27, 30, 0.38);
  --mat-form-field-leading-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-leading-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-trailing-icon-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-trailing-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-error-focus-trailing-icon-color: var(--sys-error);
  --mat-form-field-error-hover-trailing-icon-color: var(--sys-on-error-container);
  --mat-form-field-error-trailing-icon-color: var(--sys-error);
  --mat-form-field-enabled-select-arrow-color: var(--sys-on-surface-variant);
  --mat-form-field-disabled-select-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-container-text-font: var(--sys-body-large-font);
  --mat-form-field-container-text-line-height: var(--sys-body-large-line-height);
  --mat-form-field-container-text-size: var(--sys-body-large-size);
  --mat-form-field-container-text-tracking: var(--sys-body-large-tracking);
  --mat-form-field-container-text-weight: var(--sys-body-large-weight);
  --mat-form-field-subscript-text-font: var(--sys-body-small-font);
  --mat-form-field-subscript-text-line-height: var(--sys-body-small-line-height);
  --mat-form-field-subscript-text-size: var(--sys-body-small-size);
  --mat-form-field-subscript-text-tracking: var(--sys-body-small-tracking);
  --mat-form-field-subscript-text-weight: var(--sys-body-small-weight);
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
  --mat-form-field-focus-state-layer-opacity: 0;
}
html {
  --mat-select-panel-background-color: var(--sys-surface-container);
  --mat-select-enabled-trigger-text-color: var(--sys-on-surface);
  --mat-select-disabled-trigger-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-placeholder-text-color: var(--sys-on-surface-variant);
  --mat-select-enabled-arrow-color: var(--sys-on-surface-variant);
  --mat-select-disabled-arrow-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-select-focused-arrow-color: var(--sys-primary);
  --mat-select-invalid-arrow-color: var(--sys-error);
  --mat-select-trigger-text-font: var(--sys-body-large-font);
  --mat-select-trigger-text-line-height: var(--sys-body-large-line-height);
  --mat-select-trigger-text-size: var(--sys-body-large-size);
  --mat-select-trigger-text-tracking: var(--sys-body-large-tracking);
  --mat-select-trigger-text-weight: var(--sys-body-large-weight);
  --mat-select-arrow-transform: translateY(-8px);
  --mat-select-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-autocomplete-background-color: var(--sys-surface-container);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-dialog-container-color: var(--sys-surface);
  --mdc-dialog-subhead-color: var(--sys-on-surface);
  --mdc-dialog-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-dialog-subhead-font: var(--sys-headline-small-font);
  --mdc-dialog-subhead-line-height: var(--sys-headline-small-line-height);
  --mdc-dialog-subhead-size: var(--sys-headline-small-size);
  --mdc-dialog-subhead-weight: var(--sys-headline-small-weight);
  --mdc-dialog-subhead-tracking: var(--sys-headline-small-tracking);
  --mdc-dialog-supporting-text-font: var(--sys-body-medium-font);
  --mdc-dialog-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-dialog-supporting-text-size: var(--sys-body-medium-size);
  --mdc-dialog-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-dialog-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-dialog-container-shape: 28px;
}
html {
  --mat-dialog-container-elevation-shadow: none;
  --mat-dialog-container-max-width: 560px;
  --mat-dialog-container-small-max-width: calc(100vw - 32px);
  --mat-dialog-container-min-width: 280px;
  --mat-dialog-actions-alignment: flex-end;
  --mat-dialog-actions-padding: 16px 24px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px 0;
  --mat-dialog-headline-padding: 6px 24px 13px;
}
html {
  --mdc-chip-outline-color: var(--sys-outline);
  --mdc-chip-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-outline-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-opacity: 0.08;
  --mdc-chip-selected-hover-state-layer-opacity: 0.08;
  --mdc-chip-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-chip-elevated-selected-container-color: var(--sys-secondary-container);
  --mdc-chip-flat-disabled-selected-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-chip-focus-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-hover-state-layer-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-secondary-container);
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--sys-on-surface-variant);
  --mdc-chip-selected-label-text-color: var(--sys-on-secondary-container);
  --mdc-chip-with-icon-icon-color: var(--sys-on-surface-variant);
  --mdc-chip-with-icon-disabled-icon-color: var(--sys-on-surface);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-secondary-container);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--sys-on-surface-variant);
  --mdc-chip-label-text-font: var(--sys-label-large-font);
  --mdc-chip-label-text-line-height: var(--sys-label-large-line-height);
  --mdc-chip-label-text-size: var(--sys-label-large-size);
  --mdc-chip-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-chip-label-text-weight: var(--sys-label-large-weight);
  --mdc-chip-container-height: 32px;
  --mdc-chip-container-shape-radius: 8px;
  --mdc-chip-with-avatar-avatar-shape-radius: 24px;
  --mdc-chip-with-avatar-avatar-size: 24px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 1px;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 0.38;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 0.38;
  --mdc-chip-with-icon-disabled-icon-opacity: 0.38;
  --mdc-chip-elevated-container-color: transparent;
}
html {
  --mat-chip-trailing-action-state-layer-color: var(--sys-on-surface-variant);
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-secondary-container);
  --mat-chip-trailing-action-hover-state-layer-opacity: 0.08;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0.12;
  --mat-chip-selected-disabled-trailing-icon-color: var(--sys-on-surface);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-secondary-container);
  --mat-chip-disabled-container-opacity: 1;
  --mat-chip-trailing-action-opacity: 1;
  --mat-chip-trailing-action-focus-opacity: 1;
}
html {
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.08;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.08;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-switch-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-handle-color: var(--sys-on-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-primary);
  --mdc-switch-selected-focus-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-primary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-primary);
  --mdc-switch-selected-hover-track-color: var(--sys-primary);
  --mdc-switch-selected-pressed-track-color: var(--sys-primary);
  --mdc-switch-selected-track-color: var(--sys-primary);
  --mdc-switch-disabled-selected-handle-color: var(--sys-surface);
  --mdc-switch-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-switch-disabled-selected-track-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-handle-color: var(--sys-on-surface);
  --mdc-switch-disabled-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-disabled-unselected-track-color: var(--sys-surface-variant);
  --mdc-switch-selected-icon-color: var(--sys-on-primary-container);
  --mdc-switch-unselected-focus-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-focus-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-handle-color: var(--sys-outline);
  --mdc-switch-unselected-hover-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-hover-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-icon-color: var(--sys-surface-variant);
  --mdc-switch-unselected-pressed-handle-color: var(--sys-on-surface-variant);
  --mdc-switch-unselected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-switch-unselected-pressed-track-color: var(--sys-surface-variant);
  --mdc-switch-unselected-track-color: var(--sys-surface-variant);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-shape: 9999px;
  --mdc-switch-selected-icon-size: 16px;
  --mdc-switch-track-height: 32px;
  --mdc-switch-track-shape: 9999px;
  --mdc-switch-track-width: 52px;
  --mdc-switch-unselected-icon-size: 16px;
  --mdc-switch-state-layer-size: 40px;
}
html {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
  --mat-switch-label-text-font: var(--sys-body-medium-font);
  --mat-switch-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-switch-label-text-size: var(--sys-body-medium-size);
  --mat-switch-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-switch-label-text-weight: var(--sys-body-medium-weight);
  --mat-switch-disabled-selected-handle-opacity: 1;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 16px;
  --mat-switch-selected-handle-size: 24px;
  --mat-switch-pressed-handle-size: 28px;
  --mat-switch-with-icon-handle-size: 24px;
  --mat-switch-selected-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0 24px;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0 22px;
  --mat-switch-unselected-handle-horizontal-margin: 0 8px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 4px;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0 2px;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 0;
  --mat-switch-visible-track-transition: opacity 75ms;
  --mat-switch-hidden-track-transition: opacity 75ms;
  --mat-switch-track-outline-width: 2px;
  --mat-switch-selected-track-outline-width: 2px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 2px;
}
html {
  --mdc-radio-disabled-selected-icon-color: var(--sys-on-surface);
  --mdc-radio-disabled-unselected-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-focus-icon-color: var(--sys-on-surface);
  --mdc-radio-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-radio-unselected-pressed-icon-color: var(--sys-on-surface);
  --mdc-radio-selected-focus-icon-color: var(--sys-primary);
  --mdc-radio-selected-hover-icon-color: var(--sys-primary);
  --mdc-radio-selected-icon-color: var(--sys-primary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-primary);
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
}
html {
  --mat-radio-ripple-color: var(--sys-on-surface);
  --mat-radio-checked-ripple-color: var(--sys-primary);
  --mat-radio-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-radio-label-text-color: var(--sys-on-surface);
  --mat-radio-label-text-font: var(--sys-body-medium-font);
  --mat-radio-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-radio-label-text-size: var(--sys-body-medium-size);
  --mat-radio-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-radio-label-text-weight: var(--sys-body-medium-weight);
  --mat-radio-touch-target-display: block;
}
html {
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-slider-handle-color: var(--sys-primary);
  --mdc-slider-focus-handle-color: var(--sys-primary);
  --mdc-slider-hover-handle-color: var(--sys-primary);
  --mdc-slider-active-track-color: var(--sys-primary);
  --mdc-slider-inactive-track-color: var(--sys-surface-variant);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--sys-on-surface-variant);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-primary);
  --mdc-slider-disabled-active-track-color: var(--sys-on-surface);
  --mdc-slider-disabled-handle-color: var(--sys-on-surface);
  --mdc-slider-disabled-inactive-track-color: var(--sys-on-surface);
  --mdc-slider-label-container-color: var(--sys-primary);
  --mdc-slider-label-label-text-color: var(--sys-on-primary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-primary);
  --mdc-slider-with-tick-marks-disabled-container-color: var(--sys-on-surface);
  --mdc-slider-label-label-text-font: var(--sys-label-medium-font);
  --mdc-slider-label-label-text-size: var(--sys-label-medium-size);
  --mdc-slider-label-label-text-line-height: var(--sys-label-medium-line-height);
  --mdc-slider-label-label-text-tracking: var(--sys-label-medium-tracking);
  --mdc-slider-label-label-text-weight: var(--sys-label-medium-weight);
  --mdc-slider-active-track-height: 4px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 9999px;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.38;
  --mdc-slider-with-tick-marks-container-shape: 9999px;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.38;
}
html {
  --mat-slider-ripple-color: var(--sys-primary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-primary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-primary) 20%, transparent);
  --mat-slider-value-indicator-width: 28px;
  --mat-slider-value-indicator-height: 28px;
  --mat-slider-value-indicator-caret-display: none;
  --mat-slider-value-indicator-border-radius: 50% 50% 50% 0;
  --mat-slider-value-indicator-padding: 0;
  --mat-slider-value-indicator-text-transform: rotate(45deg);
  --mat-slider-value-indicator-container-transform: translateX(-50%) rotate(-45deg);
  --mat-slider-value-indicator-opacity: 1;
}
html {
  --mat-menu-item-label-text-color: var(--sys-on-surface);
  --mat-menu-item-icon-color: var(--sys-on-surface-variant);
  --mat-menu-item-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-menu-item-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-menu-container-color: var(--sys-surface-container);
  --mat-menu-divider-color: var(--sys-surface-variant);
  --mat-menu-item-label-text-font: var(--sys-label-large-font);
  --mat-menu-item-label-text-size: var(--sys-label-large-size);
  --mat-menu-item-label-text-tracking: var(--sys-label-large-tracking);
  --mat-menu-item-label-text-line-height: var(--sys-label-large-line-height);
  --mat-menu-item-label-text-weight: var(--sys-label-large-weight);
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 8px;
  --mat-menu-divider-top-spacing: 8px;
  --mat-menu-item-spacing: 12px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 12px;
  --mat-menu-item-trailing-spacing: 12px;
  --mat-menu-item-with-icon-leading-spacing: 12px;
  --mat-menu-item-with-icon-trailing-spacing: 12px;
  --mat-menu-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: var(--sys-primary-container);
  --mdc-list-list-item-disabled-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-leading-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-supporting-text-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-trailing-icon-color: var(--sys-on-surface-variant);
  --mdc-list-list-item-selected-trailing-icon-color: var(--sys-primary);
  --mdc-list-list-item-disabled-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-leading-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-label-text-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--sys-on-surface);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
  --mdc-list-list-item-label-text-font: var(--sys-body-large-font);
  --mdc-list-list-item-label-text-line-height: var(--sys-body-large-line-height);
  --mdc-list-list-item-label-text-size: var(--sys-body-large-size);
  --mdc-list-list-item-label-text-tracking: var(--sys-body-large-tracking);
  --mdc-list-list-item-label-text-weight: var(--sys-body-large-weight);
  --mdc-list-list-item-supporting-text-font: var(--sys-body-medium-font);
  --mdc-list-list-item-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-list-list-item-supporting-text-size: var(--sys-body-medium-size);
  --mdc-list-list-item-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mdc-list-list-item-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-list-list-item-trailing-supporting-text-font: var(--sys-label-small-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: var(--sys-label-small-line-height);
  --mdc-list-list-item-trailing-supporting-text-size: var(--sys-label-small-size);
  --mdc-list-list-item-trailing-supporting-text-tracking: var(--sys-label-small-tracking);
  --mdc-list-list-item-trailing-supporting-text-weight: var(--sys-label-small-weight);
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 9999px;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-label-text-opacity: 0.3;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: var(--sys-secondary-container);
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 16px;
  --mat-list-active-indicator-shape: 9999px;
}
html {
  --mat-paginator-container-text-color: var(--sys-on-surface);
  --mat-paginator-container-background-color: var(--sys-surface);
  --mat-paginator-enabled-icon-color: var(--sys-on-surface-variant);
  --mat-paginator-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-paginator-container-text-font: var(--sys-body-small-font);
  --mat-paginator-container-text-line-height: var(--sys-body-small-line-height);
  --mat-paginator-container-text-size: var(--sys-body-small-size);
  --mat-paginator-container-text-tracking: var(--sys-body-small-tracking);
  --mat-paginator-container-text-weight: var(--sys-body-small-weight);
  --mat-paginator-select-trigger-text-size: var(--sys-body-small-size);
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}
html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-color: var(--sys-primary);
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: var(--sys-surface-variant);
  --mat-tab-header-pagination-icon-color: var(--sys-on-surface);
  --mat-tab-header-inactive-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-ripple-color: var(--sys-on-surface);
  --mat-tab-header-inactive-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-inactive-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-hover-label-text-color: var(--sys-on-surface);
  --mat-tab-header-active-focus-indicator-color: var(--sys-primary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-primary);
  --mat-tab-header-label-text-font: var(--sys-title-small-font);
  --mat-tab-header-label-text-size: var(--sys-title-small-size);
  --mat-tab-header-label-text-tracking: var(--sys-title-small-tracking);
  --mat-tab-header-label-text-line-height: var(--sys-title-small-line-height);
  --mat-tab-header-label-text-weight: var(--sys-title-small-weight);
  --mat-tab-header-divider-height: 1px;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: var(--sys-surface);
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.12;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.12;
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-primary);
  --mdc-checkbox-disabled-selected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-disabled-unselected-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-primary);
  --mdc-checkbox-selected-icon-color: var(--sys-primary);
  --mdc-checkbox-unselected-focus-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-icon-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-icon-color: var(--sys-on-surface-variant);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--sys-on-surface);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-primary);
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
  --mat-checkbox-label-text-font: var(--sys-body-medium-font);
  --mat-checkbox-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-checkbox-label-text-size: var(--sys-body-medium-size);
  --mat-checkbox-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-checkbox-label-text-weight: var(--sys-body-medium-weight);
  --mat-checkbox-touch-target-display: block;
}
html {
  --mdc-text-button-label-text-color: var(--sys-primary);
  --mdc-text-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-text-button-label-text-font: var(--sys-label-large-font);
  --mdc-text-button-label-text-size: var(--sys-label-large-size);
  --mdc-text-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-text-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-text-button-container-height: 40px;
  --mdc-text-button-container-shape: 9999px;
}
html {
  --mdc-protected-button-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-container-color: var(--sys-surface);
  --mdc-protected-button-label-text-color: var(--sys-primary);
  --mdc-protected-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-protected-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-protected-button-label-text-font: var(--sys-label-large-font);
  --mdc-protected-button-label-text-size: var(--sys-label-large-size);
  --mdc-protected-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-protected-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-protected-button-container-height: 40px;
  --mdc-protected-button-container-shape: 9999px;
}
html {
  --mdc-filled-button-container-color: var(--sys-primary);
  --mdc-filled-button-label-text-color: var(--sys-on-primary);
  --mdc-filled-button-disabled-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-filled-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-filled-button-label-text-font: var(--sys-label-large-font);
  --mdc-filled-button-label-text-size: var(--sys-label-large-size);
  --mdc-filled-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-filled-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-filled-button-container-height: 40px;
  --mdc-filled-button-container-shape: 9999px;
}
html {
  --mdc-outlined-button-disabled-outline-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mdc-outlined-button-disabled-label-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-outlined-button-label-text-color: var(--sys-primary);
  --mdc-outlined-button-outline-color: var(--sys-outline);
  --mdc-outlined-button-label-text-font: var(--sys-label-large-font);
  --mdc-outlined-button-label-text-size: var(--sys-label-large-size);
  --mdc-outlined-button-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-outlined-button-label-text-weight: var(--sys-label-large-weight);
  --mdc-outlined-button-container-height: 40px;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 9999px;
}
html {
  --mat-text-button-state-layer-color: var(--sys-primary);
  --mat-text-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mat-text-button-touch-target-display: block;
  --mat-text-button-horizontal-padding: 12px;
  --mat-text-button-with-icon-horizontal-padding: 16px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: -4px;
}
html {
  --mat-protected-button-state-layer-color: var(--sys-primary);
  --mat-protected-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mat-protected-button-touch-target-display: block;
  --mat-protected-button-horizontal-padding: 24px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -8px;
}
html {
  --mat-filled-button-state-layer-color: var(--sys-on-primary);
  --mat-filled-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-primary) 12%, transparent);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mat-filled-button-touch-target-display: block;
  --mat-filled-button-horizontal-padding: 24px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -8px;
}
html {
  --mat-outlined-button-state-layer-color: var(--sys-primary);
  --mat-outlined-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
  --mat-outlined-button-touch-target-display: block;
  --mat-outlined-button-horizontal-padding: 24px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -8px;
}
html {
  --mdc-icon-button-icon-color: var(--sys-on-surface-variant);
  --mdc-icon-button-disabled-icon-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mdc-icon-button-state-layer-size: 40px;
  --mdc-icon-button-icon-size: 24px;
}
html {
  --mat-icon-button-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-disabled-state-layer-color: var(--sys-on-surface-variant);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-on-surface-variant) 12%, transparent);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
  --mat-icon-button-touch-target-display: block;
}
html {
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-label-text-font: var(--sys-label-large-font);
  --mdc-extended-fab-label-text-size: var(--sys-label-large-size);
  --mdc-extended-fab-label-text-tracking: var(--sys-label-large-tracking);
  --mdc-extended-fab-label-text-weight: var(--sys-label-large-weight);
  --mdc-extended-fab-container-height: 56px;
  --mdc-extended-fab-container-shape: 16px;
}
html {
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: var(--sys-primary-container);
  --mdc-fab-container-shape: 16px;
}
html {
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-color: var(--sys-primary-container);
  --mdc-fab-small-container-shape: 12px;
}
html {
  --mat-fab-foreground-color: var(--sys-on-primary-container);
  --mat-fab-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-foreground-color: var(--sys-on-primary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-primary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-primary-container) 12%, transparent);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-fab-small-disabled-state-foreground-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
}
html {
  --mdc-snackbar-container-color: var(--sys-inverse-surface);
  --mdc-snackbar-supporting-text-color: var(--sys-inverse-on-surface);
  --mdc-snackbar-supporting-text-font: var(--sys-body-medium-font);
  --mdc-snackbar-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mdc-snackbar-supporting-text-size: var(--sys-body-medium-size);
  --mdc-snackbar-supporting-text-weight: var(--sys-body-medium-weight);
  --mdc-snackbar-container-shape: 4px;
}
html {
  --mat-snack-bar-button-color: var(--sys-inverse-primary);
}
html {
  --mat-table-background-color: var(--sys-surface);
  --mat-table-header-headline-color: var(--sys-on-surface);
  --mat-table-row-item-label-text-color: var(--sys-on-surface);
  --mat-table-row-item-outline-color: var(--sys-outline);
  --mat-table-header-headline-font: var(--sys-title-small-font);
  --mat-table-header-headline-line-height: var(--sys-title-small-line-height);
  --mat-table-header-headline-size: var(--sys-title-small-size);
  --mat-table-header-headline-weight: var(--sys-title-small-weight);
  --mat-table-header-headline-tracking: var(--sys-title-small-tracking);
  --mat-table-row-item-label-text-font: var(--sys-body-medium-font);
  --mat-table-row-item-label-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-row-item-label-text-size: var(--sys-body-medium-size);
  --mat-table-row-item-label-text-weight: var(--sys-body-medium-weight);
  --mat-table-row-item-label-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-footer-supporting-text-font: var(--sys-body-medium-font);
  --mat-table-footer-supporting-text-line-height: var(--sys-body-medium-line-height);
  --mat-table-footer-supporting-text-size: var(--sys-body-medium-size);
  --mat-table-footer-supporting-text-weight: var(--sys-body-medium-weight);
  --mat-table-footer-supporting-text-tracking: var(--sys-body-medium-tracking);
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
  --mat-table-row-item-outline-width: 1px;
}
html {
  --mdc-circular-progress-active-indicator-color: var(--sys-primary);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}
html {
  --mat-badge-background-color: var(--sys-error);
  --mat-badge-text-color: var(--sys-on-error);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-error) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-error);
  --mat-badge-text-font: var(--sys-label-small-font);
  --mat-badge-text-size: var(--sys-label-small-size);
  --mat-badge-text-weight: var(--sys-label-small-weight);
  --mat-badge-large-size-text-size: var(--sys-label-small-size);
  --mat-badge-container-shape: 9999px;
  --mat-badge-container-size: 16px;
  --mat-badge-small-size-container-size: 6px;
  --mat-badge-large-size-container-size: 16px;
  --mat-badge-legacy-container-size: unset;
  --mat-badge-legacy-small-size-container-size: unset;
  --mat-badge-legacy-large-size-container-size: unset;
  --mat-badge-container-offset: -12px 0;
  --mat-badge-small-size-container-offset: -6px 0;
  --mat-badge-large-size-container-offset: -12px 0;
  --mat-badge-container-overlap-offset: -12px;
  --mat-badge-small-size-container-overlap-offset: -6px;
  --mat-badge-large-size-container-overlap-offset: -12px;
  --mat-badge-container-padding: 0 4px;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0 4px;
  --mat-badge-line-height: 16px;
  --mat-badge-small-size-text-size: 0;
  --mat-badge-small-size-line-height: 6px;
  --mat-badge-large-size-line-height: 16px;
}
html {
  --mat-bottom-sheet-container-text-color: var(--sys-on-surface);
  --mat-bottom-sheet-container-background-color: var(--sys-surface-container-low);
  --mat-bottom-sheet-container-text-font: var(--sys-body-large-font);
  --mat-bottom-sheet-container-text-line-height: var(--sys-body-large-line-height);
  --mat-bottom-sheet-container-text-size: var(--sys-body-large-size);
  --mat-bottom-sheet-container-text-tracking: var(--sys-body-large-tracking);
  --mat-bottom-sheet-container-text-weight: var(--sys-body-large-weight);
  --mat-bottom-sheet-container-shape: 28px;
}
html {
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.08;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-standard-button-toggle-text-color: var(--sys-on-surface);
  --mat-standard-button-toggle-state-layer-color: var(--sys-on-surface);
  --mat-standard-button-toggle-selected-state-background-color: var(--sys-secondary-container);
  --mat-standard-button-toggle-selected-state-text-color: var(--sys-on-secondary-container);
  --mat-standard-button-toggle-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-standard-button-toggle-disabled-selected-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-standard-button-toggle-divider-color: var(--sys-outline);
  --mat-standard-button-toggle-label-text-font: var(--sys-label-large-font);
  --mat-standard-button-toggle-label-text-line-height: var(--sys-label-large-line-height);
  --mat-standard-button-toggle-label-text-size: var(--sys-label-large-size);
  --mat-standard-button-toggle-label-text-tracking: var(--sys-label-large-tracking);
  --mat-standard-button-toggle-label-text-weight: var(--sys-label-large-weight);
  --mat-standard-button-toggle-height: 40px;
  --mat-standard-button-toggle-shape: 9999px;
  --mat-standard-button-toggle-background-color: transparent;
  --mat-standard-button-toggle-disabled-state-background-color: transparent;
}
html {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-primary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-primary);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-datepicker-calendar-date-hover-state-background-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-datepicker-toggle-active-state-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: var(--sys-secondary-container);
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: var(--sys-secondary);
  --mat-datepicker-toggle-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-body-label-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-period-button-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-period-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-header-text-color: var(--sys-on-surface-variant);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-primary);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-date-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-primary);
  --mat-datepicker-range-input-separator-color: var(--sys-on-surface);
  --mat-datepicker-range-input-disabled-state-separator-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-range-input-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-datepicker-calendar-container-background-color: var(--sys-surface-container-high);
  --mat-datepicker-calendar-container-text-color: var(--sys-on-surface);
  --mat-datepicker-calendar-text-font: var(--sys-body-medium-font);
  --mat-datepicker-calendar-text-size: var(--sys-body-medium-size);
  --mat-datepicker-calendar-body-label-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-body-label-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-period-button-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-period-button-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-header-text-size: var(--sys-title-small-size);
  --mat-datepicker-calendar-header-text-weight: var(--sys-title-small-weight);
  --mat-datepicker-calendar-container-shape: 16px;
  --mat-datepicker-calendar-container-touch-shape: 28px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-divider-color: transparent;
  --mat-datepicker-calendar-date-outline-color: transparent;
}
html {
  --mat-divider-color: var(--sys-outline);
  --mat-divider-width: 1px;
}
html {
  --mat-expansion-container-background-color: var(--sys-surface);
  --mat-expansion-container-text-color: var(--sys-on-surface);
  --mat-expansion-actions-divider-color: var(--sys-outline);
  --mat-expansion-header-hover-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 8%, transparent);
  --mat-expansion-header-focus-state-layer-color: color-mix(in srgb, var(--sys-on-surface) 12%, transparent);
  --mat-expansion-header-disabled-state-text-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-expansion-header-text-color: var(--sys-on-surface);
  --mat-expansion-header-description-color: var(--sys-on-surface-variant);
  --mat-expansion-header-indicator-color: var(--sys-on-surface-variant);
  --mat-expansion-header-text-font: var(--sys-title-medium-font);
  --mat-expansion-header-text-size: var(--sys-title-medium-size);
  --mat-expansion-header-text-weight: var(--sys-title-medium-weight);
  --mat-expansion-header-text-line-height: var(--sys-title-medium-line-height);
  --mat-expansion-header-text-tracking: var(--sys-title-medium-tracking);
  --mat-expansion-container-text-font: var(--sys-body-large-font);
  --mat-expansion-container-text-line-height: var(--sys-body-large-line-height);
  --mat-expansion-container-text-size: var(--sys-body-large-size);
  --mat-expansion-container-text-tracking: var(--sys-body-large-tracking);
  --mat-expansion-container-text-weight: var(--sys-body-large-weight);
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
  --mat-expansion-container-shape: 12px;
  --mat-expansion-legacy-header-indicator-display: none;
  --mat-expansion-header-indicator-display: inline-block;
}
html {
  --mat-grid-list-tile-header-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-header-secondary-text-size: var(--sys-body-medium);
  --mat-grid-list-tile-footer-primary-text-size: var(--sys-body-large);
  --mat-grid-list-tile-footer-secondary-text-size: var(--sys-body-medium);
}
html {
  --mat-icon-color: inherit;
}
html {
  --mat-sidenav-container-background-color: var(--sys-surface);
  --mat-sidenav-container-text-color: var(--sys-on-surface-variant);
  --mat-sidenav-content-background-color: var(--sys-background);
  --mat-sidenav-content-text-color: var(--sys-on-background);
  --mat-sidenav-scrim-color: rgba(48, 47, 55, 0.4);
  --mat-sidenav-container-shape: 16px;
  --mat-sidenav-container-elevation-shadow: none;
  --mat-sidenav-container-width: 360px;
  --mat-sidenav-container-divider-color: transparent;
}
html {
  --mat-stepper-header-icon-foreground-color: var(--sys-surface);
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-container-color: var(--sys-surface);
  --mat-stepper-line-color: var(--sys-outline);
  --mat-stepper-header-hover-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 8%, transparent);
  --mat-stepper-header-focus-state-layer-color: color-mix(in srgb, var(--sys-inverse-surface) 12%, transparent);
  --mat-stepper-header-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-optional-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-selected-state-label-text-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-label-text-color: var(--sys-error);
  --mat-stepper-header-icon-background-color: var(--sys-on-surface-variant);
  --mat-stepper-header-error-state-icon-foreground-color: var(--sys-error);
  --mat-stepper-container-text-font: var(--sys-body-medium-font);
  --mat-stepper-header-label-text-font: var(--sys-title-small-font);
  --mat-stepper-header-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-error-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-size: var(--sys-title-small-size);
  --mat-stepper-header-selected-state-label-text-weight: var(--sys-title-small-weight);
  --mat-stepper-header-height: 72px;
  --mat-stepper-header-focus-state-layer-shape: 12px;
  --mat-stepper-header-hover-state-layer-shape: 12px;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html {
  --mat-sort-arrow-color: var(--sys-on-surface);
}
html {
  --mat-toolbar-container-background-color: var(--sys-surface);
  --mat-toolbar-container-text-color: var(--sys-on-surface);
  --mat-toolbar-title-text-font: var(--sys-title-large-font);
  --mat-toolbar-title-text-line-height: var(--sys-title-large-line-height);
  --mat-toolbar-title-text-size: var(--sys-title-large-size);
  --mat-toolbar-title-text-tracking: var(--sys-title-large-tracking);
  --mat-toolbar-title-text-weight: var(--sys-title-large-weight);
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}
html {
  --mat-tree-container-background-color: var(--sys-surface);
  --mat-tree-node-text-color: var(--sys-on-surface);
  --mat-tree-node-text-font: var(--sys-body-large-font);
  --mat-tree-node-text-size: var(--sys-body-large-size);
  --mat-tree-node-text-weight: var(--sys-body-large-weight);
  --mat-tree-node-min-height: 48px;
}
html {
  --mat-timepicker-container-background-color: var(--sys-surface-container);
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
html {
  --sys-background: #ffffff;
  --sys-error: #ba1a1a;
  --sys-error-container: #ffdad6;
  --sys-inverse-on-surface: #f4eff3;
  --sys-inverse-primary: #c5c2f1;
  --sys-inverse-surface: #313033;
  --sys-on-background: #1c1b1e;
  --sys-on-error: #ffffff;
  --sys-on-error-container: #93000a;
  --sys-on-primary: #ffffff;
  --sys-on-primary-container: #44426a;
  --sys-on-primary-fixed: #19173c;
  --sys-on-primary-fixed-variant: #44426a;
  --sys-on-secondary: #ffffff;
  --sys-on-secondary-container: #464556;
  --sys-on-secondary-fixed: #1b1a29;
  --sys-on-secondary-fixed-variant: #464556;
  --sys-on-surface: #1c1b1e;
  --sys-on-surface-variant: #47464e;
  --sys-on-tertiary: #ffffff;
  --sys-on-tertiary-container: #633a53;
  --sys-on-tertiary-fixed: #310f26;
  --sys-on-tertiary-fixed-variant: #633a53;
  --sys-outline: #78767f;
  --sys-outline-variant: #c8c5cf;
  --sys-primary: #5c5a84;
  --sys-primary-container: #e3dfff;
  --sys-primary-fixed: #e3dfff;
  --sys-primary-fixed-dim: #c5c2f1;
  --sys-scrim: #000000;
  --sys-secondary: #5e5c6f;
  --sys-secondary-container: #e0e1f6;
  --sys-secondary-fixed: #e0e1f6;
  --sys-secondary-fixed-dim: #c7c4d9;
  --sys-shadow: #000000;
  --sys-surface: #ffffff;
  --sys-surface-bright: #ffffff;
  --sys-surface-container: #fafafa;
  --sys-surface-container-high: #ebe7eb;
  --sys-surface-container-highest: #e5e1e5;
  --sys-surface-container-low: #fcfcfc;
  --sys-surface-container-lowest: #ffffff;
  --sys-surface-dim: #ddd9dd;
  --sys-surface-tint: #5c5a84;
  --sys-surface-variant: #e5e1eb;
  --sys-tertiary: #7d516b;
  --sys-tertiary-container: #ffd8ec;
  --sys-tertiary-fixed: #ffd8ec;
  --sys-tertiary-fixed-dim: #eeb7d5;
  --sys-neutral-variant20: #302f37;
  --sys-neutral10: #1c1b1e;
}
html {
  --sys-body-large: 400 1rem / 1.5rem Roboto, sans-serif;
  --sys-body-large-font: Roboto, sans-serif;
  --sys-body-large-line-height: 1.5rem;
  --sys-body-large-size: 1rem;
  --sys-body-large-tracking: 0.031rem;
  --sys-body-large-weight: 400;
  --sys-body-medium: 400 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-body-medium-font: Roboto, sans-serif;
  --sys-body-medium-line-height: 1.25rem;
  --sys-body-medium-size: 0.875rem;
  --sys-body-medium-tracking: 0.016rem;
  --sys-body-medium-weight: 400;
  --sys-body-small: 400 0.75rem / 1rem Roboto, sans-serif;
  --sys-body-small-font: Roboto, sans-serif;
  --sys-body-small-line-height: 1rem;
  --sys-body-small-size: 0.75rem;
  --sys-body-small-tracking: 0.025rem;
  --sys-body-small-weight: 400;
  --sys-display-large: 400 3.562rem / 4rem Roboto, sans-serif;
  --sys-display-large-font: Roboto, sans-serif;
  --sys-display-large-line-height: 4rem;
  --sys-display-large-size: 3.562rem;
  --sys-display-large-tracking: -0.016rem;
  --sys-display-large-weight: 400;
  --sys-display-medium: 400 2.812rem / 3.25rem Roboto, sans-serif;
  --sys-display-medium-font: Roboto, sans-serif;
  --sys-display-medium-line-height: 3.25rem;
  --sys-display-medium-size: 2.812rem;
  --sys-display-medium-tracking: 0;
  --sys-display-medium-weight: 400;
  --sys-display-small: 400 2.25rem / 2.75rem Roboto, sans-serif;
  --sys-display-small-font: Roboto, sans-serif;
  --sys-display-small-line-height: 2.75rem;
  --sys-display-small-size: 2.25rem;
  --sys-display-small-tracking: 0;
  --sys-display-small-weight: 400;
  --sys-headline-large: 400 2rem / 2.5rem Roboto, sans-serif;
  --sys-headline-large-font: Roboto, sans-serif;
  --sys-headline-large-line-height: 2.5rem;
  --sys-headline-large-size: 2rem;
  --sys-headline-large-tracking: 0;
  --sys-headline-large-weight: 400;
  --sys-headline-medium: 400 1.75rem / 2.25rem Roboto, sans-serif;
  --sys-headline-medium-font: Roboto, sans-serif;
  --sys-headline-medium-line-height: 2.25rem;
  --sys-headline-medium-size: 1.75rem;
  --sys-headline-medium-tracking: 0;
  --sys-headline-medium-weight: 400;
  --sys-headline-small: 400 1.5rem / 2rem Roboto, sans-serif;
  --sys-headline-small-font: Roboto, sans-serif;
  --sys-headline-small-line-height: 2rem;
  --sys-headline-small-size: 1.5rem;
  --sys-headline-small-tracking: 0;
  --sys-headline-small-weight: 400;
  --sys-label-large: 500 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-label-large-font: Roboto, sans-serif;
  --sys-label-large-line-height: 1.25rem;
  --sys-label-large-size: 0.875rem;
  --sys-label-large-tracking: 0.006rem;
  --sys-label-large-weight: 500;
  --sys-label-large-weight-prominent: 700;
  --sys-label-medium: 500 0.75rem / 1rem Roboto, sans-serif;
  --sys-label-medium-font: Roboto, sans-serif;
  --sys-label-medium-line-height: 1rem;
  --sys-label-medium-size: 0.75rem;
  --sys-label-medium-tracking: 0.031rem;
  --sys-label-medium-weight: 500;
  --sys-label-medium-weight-prominent: 700;
  --sys-label-small: 500 0.688rem / 1rem Roboto, sans-serif;
  --sys-label-small-font: Roboto, sans-serif;
  --sys-label-small-line-height: 1rem;
  --sys-label-small-size: 0.688rem;
  --sys-label-small-tracking: 0.031rem;
  --sys-label-small-weight: 500;
  --sys-title-large: 400 1.375rem / 1.75rem Roboto, sans-serif;
  --sys-title-large-font: Roboto, sans-serif;
  --sys-title-large-line-height: 1.75rem;
  --sys-title-large-size: 1.375rem;
  --sys-title-large-tracking: 0;
  --sys-title-large-weight: 400;
  --sys-title-medium: 500 1rem / 1.5rem Roboto, sans-serif;
  --sys-title-medium-font: Roboto, sans-serif;
  --sys-title-medium-line-height: 1.5rem;
  --sys-title-medium-size: 1rem;
  --sys-title-medium-tracking: 0.009rem;
  --sys-title-medium-weight: 500;
  --sys-title-small: 500 0.875rem / 1.25rem Roboto, sans-serif;
  --sys-title-small-font: Roboto, sans-serif;
  --sys-title-small-line-height: 1.25rem;
  --sys-title-small-size: 0.875rem;
  --sys-title-small-tracking: 0.006rem;
  --sys-title-small-weight: 500;
}

.mat-display-large, .mat-typography .mat-display-large, .mat-typography h1 {
  font: var(--sys-display-large);
  letter-spacing: var(--sys-display-large-tracking);
  margin: 0 0 0.5em;
}

.mat-display-medium, .mat-typography .mat-display-medium, .mat-typography h2 {
  font: var(--sys-display-medium);
  letter-spacing: var(--sys-display-medium-tracking);
  margin: 0 0 0.5em;
}

.mat-display-small, .mat-typography .mat-display-small, .mat-typography h3 {
  font: var(--sys-display-small);
  letter-spacing: var(--sys-display-small-tracking);
  margin: 0 0 0.5em;
}

.mat-headline-large, .mat-typography .mat-headline-large, .mat-typography h4 {
  font: var(--sys-headline-large);
  letter-spacing: var(--sys-headline-large-tracking);
  margin: 0 0 0.5em;
}

.mat-headline-medium, .mat-typography .mat-headline-medium, .mat-typography h5 {
  font: var(--sys-headline-medium);
  letter-spacing: var(--sys-headline-medium-tracking);
  margin: 0 0 0.5em;
}

.mat-headline-small, .mat-typography .mat-headline-small, .mat-typography h6 {
  font: var(--sys-headline-small);
  letter-spacing: var(--sys-headline-small-tracking);
  margin: 0 0 0.5em;
}

.mat-title-large, .mat-typography .mat-title-large {
  font: var(--sys-title-large);
  letter-spacing: var(--sys-title-large-tracking);
}

.mat-title-medium, .mat-typography .mat-title-medium {
  font: var(--sys-title-medium);
  letter-spacing: var(--sys-title-medium-tracking);
}

.mat-title-small, .mat-typography .mat-title-small {
  font: var(--sys-title-small);
  letter-spacing: var(--sys-title-small-tracking);
}

.mat-body-large, .mat-typography .mat-body-large, .mat-typography {
  font: var(--sys-body-large);
  letter-spacing: var(--sys-body-large-tracking);
}

.mat-body-large p, .mat-typography .mat-body-large p, .mat-typography p {
  margin: 0 0 0.75em;
}

.mat-body-medium, .mat-typography .mat-body-medium {
  font: var(--sys-body-medium);
  letter-spacing: var(--sys-body-medium-tracking);
}

.mat-body-small, .mat-typography .mat-body-small {
  font: var(--sys-body-small);
  letter-spacing: var(--sys-body-small-tracking);
}

.mat-label-large, .mat-typography .mat-label-large {
  font: var(--sys-label-large);
  letter-spacing: var(--sys-label-large-tracking);
}

.mat-label-medium, .mat-typography .mat-label-medium {
  font: var(--sys-label-medium);
  letter-spacing: var(--sys-label-medium-tracking);
}

.mat-label-small, .mat-typography .mat-label-small {
  font: var(--sys-label-small);
  letter-spacing: var(--sys-label-small-tracking);
}

.mat-primary {
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-primary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-primary);
}
.mat-primary.mat-icon {
  --mat-icon-color: var(--sys-primary);
}
.mat-primary.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-primary.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
.mat-primary.mat-mdc-button-base {
  --mdc-icon-button-icon-color: var(--sys-primary);
}
.mat-primary.mat-mdc-button-base {
  --mat-icon-button-state-layer-color: var(--sys-primary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-primary) 12%, transparent);
}
.mat-primary .mdc-list-item__start,
.mat-primary .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-badge {
  --mat-badge-background-color: var(--sys-primary);
  --mat-badge-text-color: var(--sys-on-primary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-primary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-primary);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--sys-on-tertiary-container);
  --mat-option-selected-state-layer-color: var(--sys-tertiary-container);
}
.mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--sys-tertiary);
}
.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-tertiary);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-tertiary);
}
.mat-accent {
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-tertiary);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-tertiary);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-tertiary);
}
.mat-accent.mat-icon {
  --mat-icon-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-accent.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-tertiary);
  --mdc-slider-focus-handle-color: var(--sys-tertiary);
  --mdc-slider-hover-handle-color: var(--sys-tertiary);
  --mdc-slider-active-track-color: var(--sys-tertiary);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-tertiary);
  --mdc-slider-label-container-color: var(--sys-tertiary);
  --mdc-slider-label-label-text-color: var(--sys-on-tertiary);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-tertiary);
}
.mat-accent.mat-mdc-slider {
  --mat-slider-ripple-color: var(--sys-tertiary);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-tertiary) 20%, transparent);
}
.mat-accent.mat-mdc-tab-group, .mat-accent.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-tab-group, .mat-accent.mat-mdc-tab-nav-bar {
  --mat-tab-header-active-focus-indicator-color: var(--sys-tertiary);
  --mat-tab-header-active-hover-indicator-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-handle-color: var(--sys-on-tertiary);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-tertiary);
  --mdc-switch-selected-focus-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-tertiary-container);
  --mdc-switch-selected-focus-track-color: var(--sys-tertiary);
  --mdc-switch-selected-hover-track-color: var(--sys-tertiary);
  --mdc-switch-selected-pressed-track-color: var(--sys-tertiary);
  --mdc-switch-selected-track-color: var(--sys-tertiary);
  --mdc-switch-selected-icon-color: var(--sys-on-tertiary-container);
}
.mat-accent.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
.mat-accent.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-radio-button {
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-tertiary);
  --mdc-linear-progress-track-color: var(--sys-tertiary-container);
}
.mat-accent.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-tertiary);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-form-field {
  --mdc-outlined-text-field-caret-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-tertiary);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-form-field {
  --mat-form-field-focus-select-arrow-color: var(--sys-tertiary);
}
.mat-accent.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-tertiary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-tertiary-container);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: var(--sys-primary-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-tertiary);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mdc-protected-button-label-text-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mdc-filled-button-container-color: var(--sys-tertiary);
  --mdc-filled-button-label-text-color: var(--sys-on-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mdc-outlined-button-label-text-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mat-text-button-state-layer-color: var(--sys-tertiary);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
.mat-accent.mat-mdc-button-base {
  --mat-protected-button-state-layer-color: var(--sys-tertiary);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
.mat-accent.mat-mdc-button-base {
  --mat-filled-button-state-layer-color: var(--sys-on-tertiary);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-tertiary) 12%, transparent);
}
.mat-accent.mat-mdc-button-base {
  --mat-outlined-button-state-layer-color: var(--sys-tertiary);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
.mat-accent.mat-mdc-button-base {
  --mdc-icon-button-icon-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-button-base {
  --mat-icon-button-state-layer-color: var(--sys-tertiary);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-tertiary) 12%, transparent);
}
.mat-accent.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-tertiary-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-tertiary-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-tertiary-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-tertiary-container);
}
.mat-accent.mat-mdc-standard-chip {
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-tertiary-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-tertiary-container);
}
.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-tertiary);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-icon-color: var(--sys-tertiary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-tertiary);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-tertiary);
}
.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-selected-focus-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-hover-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-icon-color: var(--sys-tertiary);
  --mdc-radio-selected-pressed-icon-color: var(--sys-tertiary);
}
.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mat-radio-checked-ripple-color: var(--sys-tertiary);
}
.mat-accent.mat-mdc-fab, .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-container-color: var(--sys-tertiary-container);
}
.mat-accent.mat-mdc-fab, .mat-accent.mat-mdc-mini-fab {
  --mdc-fab-small-container-color: var(--sys-tertiary-container);
}
.mat-accent.mat-mdc-fab, .mat-accent.mat-mdc-mini-fab {
  --mat-fab-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
}
.mat-accent.mat-mdc-fab, .mat-accent.mat-mdc-mini-fab {
  --mat-fab-small-foreground-color: var(--sys-on-tertiary-container);
  --mat-fab-small-state-layer-color: var(--sys-on-tertiary-container);
  --mat-fab-small-ripple-color: color-mix(in srgb, var(--sys-on-tertiary-container) 12%, transparent);
}

.mat-badge-accent {
  --mat-badge-background-color: var(--sys-tertiary);
  --mat-badge-text-color: var(--sys-on-tertiary);
  --mat-badge-disabled-state-background-color: color-mix(in srgb, var(--sys-tertiary) 38%, transparent);
  --mat-badge-disabled-state-text-color: var(--sys-on-tertiary);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: var(--sys-on-error-container);
  --mat-option-selected-state-layer-color: var(--sys-error-container);
}
.mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--sys-error);
}
.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--sys-error);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--sys-on-error);
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--sys-error);
}
.mat-warn {
  --mat-stepper-header-selected-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-selected-state-icon-foreground-color: var(--sys-on-error);
  --mat-stepper-header-edit-state-icon-background-color: var(--sys-error);
  --mat-stepper-header-edit-state-icon-foreground-color: var(--sys-on-error);
}
.mat-warn.mat-icon {
  --mat-icon-color: var(--sys-error);
}
.mat-warn.mat-mdc-checkbox {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
}
.mat-warn.mat-mdc-checkbox {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-warn.mat-mdc-slider {
  --mdc-slider-handle-color: var(--sys-error);
  --mdc-slider-focus-handle-color: var(--sys-error);
  --mdc-slider-hover-handle-color: var(--sys-error);
  --mdc-slider-active-track-color: var(--sys-error);
  --mdc-slider-with-tick-marks-active-container-color: var(--sys-on-error);
  --mdc-slider-label-container-color: var(--sys-error);
  --mdc-slider-label-label-text-color: var(--sys-on-error);
  --mdc-slider-with-overlap-handle-outline-color: var(--sys-on-error);
}
.mat-warn.mat-mdc-slider {
  --mat-slider-ripple-color: var(--sys-error);
  --mat-slider-hover-state-layer-color: color-mix(in srgb, var(--sys-error) 5%, transparent);
  --mat-slider-focus-state-layer-color: color-mix(in srgb, var(--sys-error) 20%, transparent);
}
.mat-warn.mat-mdc-tab-group, .mat-warn.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: var(--sys-error);
}
.mat-warn.mat-mdc-tab-group, .mat-warn.mat-mdc-tab-nav-bar {
  --mat-tab-header-active-focus-indicator-color: var(--sys-error);
  --mat-tab-header-active-hover-indicator-color: var(--sys-error);
}
.mat-warn.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: var(--sys-error);
  --mdc-switch-selected-handle-color: var(--sys-on-error);
  --mdc-switch-selected-hover-state-layer-color: var(--sys-error);
  --mdc-switch-selected-pressed-state-layer-color: var(--sys-error);
  --mdc-switch-selected-focus-handle-color: var(--sys-error-container);
  --mdc-switch-selected-hover-handle-color: var(--sys-error-container);
  --mdc-switch-selected-pressed-handle-color: var(--sys-error-container);
  --mdc-switch-selected-focus-track-color: var(--sys-error);
  --mdc-switch-selected-hover-track-color: var(--sys-error);
  --mdc-switch-selected-pressed-track-color: var(--sys-error);
  --mdc-switch-selected-track-color: var(--sys-error);
  --mdc-switch-selected-icon-color: var(--sys-on-error-container);
}
.mat-warn.mat-mdc-slide-toggle {
  --mat-switch-track-outline-color: var(--sys-outline);
  --mat-switch-disabled-unselected-track-outline-color: var(--sys-on-surface);
  --mat-switch-label-text-color: var(--sys-on-surface);
}
.mat-warn.mat-mdc-form-field {
  --mat-select-focused-arrow-color: var(--sys-error);
}
.mat-warn.mat-mdc-radio-button {
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
}
.mat-warn.mat-mdc-radio-button {
  --mat-radio-checked-ripple-color: var(--sys-error);
}
.mat-warn.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: var(--sys-error);
  --mdc-linear-progress-track-color: var(--sys-error-container);
}
.mat-warn.mat-mdc-form-field {
  --mdc-filled-text-field-caret-color: var(--sys-error);
  --mdc-filled-text-field-focus-active-indicator-color: var(--sys-error);
  --mdc-filled-text-field-focus-label-text-color: var(--sys-error);
}
.mat-warn.mat-mdc-form-field {
  --mdc-outlined-text-field-caret-color: var(--sys-error);
  --mdc-outlined-text-field-focus-outline-color: var(--sys-error);
  --mdc-outlined-text-field-focus-label-text-color: var(--sys-error);
}
.mat-warn.mat-mdc-form-field {
  --mat-form-field-focus-select-arrow-color: var(--sys-error);
}
.mat-warn.mat-datepicker-content {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--sys-on-error);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--sys-error);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-in-range-state-background-color: var(--sys-error-container);
  --mat-datepicker-calendar-date-today-outline-color: var(--sys-error);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--sys-error);
}
.mat-warn.mat-mdc-button-base {
  --mdc-text-button-label-text-color: var(--sys-error);
}
.mat-warn.mat-mdc-button-base {
  --mdc-protected-button-label-text-color: var(--sys-error);
}
.mat-warn.mat-mdc-button-base {
  --mdc-filled-button-container-color: var(--sys-error);
  --mdc-filled-button-label-text-color: var(--sys-on-error);
}
.mat-warn.mat-mdc-button-base {
  --mdc-outlined-button-label-text-color: var(--sys-error);
}
.mat-warn.mat-mdc-button-base {
  --mat-text-button-state-layer-color: var(--sys-error);
  --mat-text-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
.mat-warn.mat-mdc-button-base {
  --mat-protected-button-state-layer-color: var(--sys-error);
  --mat-protected-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
.mat-warn.mat-mdc-button-base {
  --mat-filled-button-state-layer-color: var(--sys-on-error);
  --mat-filled-button-ripple-color: color-mix(in srgb, var(--sys-on-error) 12%, transparent);
}
.mat-warn.mat-mdc-button-base {
  --mat-outlined-button-state-layer-color: var(--sys-error);
  --mat-outlined-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
.mat-warn.mat-mdc-button-base {
  --mdc-icon-button-icon-color: var(--sys-error);
}
.mat-warn.mat-mdc-button-base {
  --mat-icon-button-state-layer-color: var(--sys-error);
  --mat-icon-button-ripple-color: color-mix(in srgb, var(--sys-error) 12%, transparent);
}
.mat-warn.mat-mdc-standard-chip {
  --mdc-chip-elevated-selected-container-color: var(--sys-error-container);
  --mdc-chip-selected-hover-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-focus-state-layer-color: var(--sys-on-error-container);
  --mdc-chip-selected-label-text-color: var(--sys-on-error-container);
  --mdc-chip-with-icon-selected-icon-color: var(--sys-on-error-container);
}
.mat-warn.mat-mdc-standard-chip {
  --mat-chip-selected-trailing-action-state-layer-color: var(--sys-on-error-container);
  --mat-chip-selected-trailing-icon-color: var(--sys-on-error-container);
}
.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-pressed-icon-color: var(--sys-error);
  --mdc-checkbox-selected-checkmark-color: var(--sys-on-error);
  --mdc-checkbox-selected-focus-icon-color: var(--sys-error);
  --mdc-checkbox-selected-hover-icon-color: var(--sys-error);
  --mdc-checkbox-selected-icon-color: var(--sys-error);
  --mdc-checkbox-selected-focus-state-layer-color: var(--sys-error);
  --mdc-checkbox-selected-hover-state-layer-color: var(--sys-error);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--sys-error);
}
.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mat-checkbox-disabled-label-color: color-mix(in srgb, var(--sys-on-surface) 38%, transparent);
  --mat-checkbox-label-text-color: var(--sys-on-surface);
}
.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-selected-focus-icon-color: var(--sys-error);
  --mdc-radio-selected-hover-icon-color: var(--sys-error);
  --mdc-radio-selected-icon-color: var(--sys-error);
  --mdc-radio-selected-pressed-icon-color: var(--sys-error);
}
.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mat-radio-checked-ripple-color: var(--sys-error);
}

html {
  --mat-badge-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: normal;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 15px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
}
html {
  --mat-standard-button-toggle-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 15px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: normal;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-stepper-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-size: 15px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-toolbar-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: normal;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-node-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-option-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 15px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 15px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-card-title-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: normal;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-card-subtitle-text-line-height: 24px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: normal;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-text-field-label-text-size: 15px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-outlined-text-field-label-text-size: 15px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
}
html {
  --mat-form-field-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 15px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 15px;
  --mat-form-field-subscript-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-trigger-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 15px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mdc-dialog-subhead-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: normal;
  --mdc-chip-label-text-weight: 400;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: normal;
  --mat-switch-label-text-weight: 400;
}

html {
  --mat-radio-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: normal;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-label-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-menu-item-label-text-size: 15px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

html {
  --mat-paginator-container-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mat-checkbox-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: normal;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-extended-fab-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-header-headline-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-header-headline-line-height: 24px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: normal;
  --mat-table-footer-supporting-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: normal;
}