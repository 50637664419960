.mat-mdc-dialog-container {
  position: relative;
  .btn-back {
    top: 3px;
    right: 3px;
  }
}

.modal-title {
  @include flex();
  padding-bottom: 10px;
  border-bottom: 2px $clr-grey solid;
  .mat-mdc-dialog-title {
    margin: 0;
  }
  .select-outline {
    margin-right: 30px;
    .mat-mdc-form-field {
      width: 210px;
    }
  }
}

.modal-cont {
  padding: 30px 0 0;
}

.btn.btn-close {
  width: 26px;
  height: 26px;
  line-height: 26px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: $clr-grey;
  .mat-icon {
    font-size: 20px;
    line-height: 20px;
    width: 20px;
    height: 20px;
  }
}

.modal-loader {
  height: 65vh;
  @include flex(row, center, center);
  &--sm {
    height: auto;
    padding: 100px 0;
  }
}
